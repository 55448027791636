import React from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { FormCheck } from "react-bootstrap";
import { AsDateTime } from "src/components/ui/formatters";
import { Link } from "react-router-dom";
import ClipboardCell from "src/pages/admin/properties/table-page/components/ClipboardCell";
import AsPrice from "src/components/ui/formatters/AsPrice";
import AsNumber from "src/components/ui/formatters/AsNumber";
import AsPercent from "src/components/ui/formatters/AsPercent";
import MarketCampaignPropertyContactUpdate
  from "src/pages/agent/market-campaigns/components/MarketCampaignPropertyContactUpdate";
import MarketCampaignPropertyContactStatusToggle
  from "src/pages/agent/market-campaigns/components/MarketCampaignPropertyContactStatusToggle";
import config from "src/config";
import MarketCampaignPropertyStatusToggle
  from "src/pages/agent/market-campaigns/components/MarketCampaignPropertyStatusToggle";
import MarketCampaignPropertyContactConversationModal
  from "src/pages/agent/market-campaigns/components/MarketCampaignPropertyContactConversationModal";

type PropertyTableData = AgentAPI.MarketCampaign.PropertyList.Response["results"][0];

export const defaultColumn: Partial<ColumnDef<PropertyTableData>> = {
  minSize: 50,
  maxSize: 500,
  enableResizing: true,
};

const columnHelper = createColumnHelper<PropertyTableData>();

export const defaultColumns: ColumnDef<PropertyTableData, any>[] = [
  columnHelper.display({
    id: "select-col",
    size: 50,
    enableResizing: false,
    header: ({ table }) => (
      <FormCheck
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <FormCheck
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor("id", {
    header: "ID",
    size: 100,
  }),
  columnHelper.accessor("internal_listed_at", {
    header: "Internal listed at",
    size: 180,
    cell: (cell) => (
      <AsDateTime value={cell.row.original.internal_listed_at} />
    ),
  }),
  columnHelper.accessor("title", {
    header: "Title",
    cell: (cell) => (
      <Link to={`/property-details/${cell.row.original.id}/`}>
        {cell.row.original.title}
      </Link>
    ),
  }),
  columnHelper.accessor("market.name", {
    header: "City",
  }),
  columnHelper.accessor("market_state.code", {
    header: "State",
    size: 80,
  }),
  columnHelper.accessor("address", {
    header: "Address",
    size: 80,
    cell: (cell) => (
      <ClipboardCell
        value={cell.row.original.address}
      />
    ),
  }),
  columnHelper.accessor("rent_price", {
    header: "Rent Price",
    size: 120,
    cell: (cell) => (
      <AsPrice value={cell.row.original.rent_price} />
    ),
  }),
  columnHelper.accessor("bedrooms", {
    header: "Beds",
    size: 60,
  }),
  columnHelper.accessor("bathrooms", {
    header: "Baths",
    size: 70,
    cell: (cell) => (
      <AsNumber value={cell.row.original.bathrooms} decimalPlaces={1} />
    ),
  }),
  columnHelper.accessor("property_area", {
    header: "Sqft",
    size: 80,
    cell: (cell) => (
      <AsNumber value={cell.row.original.property_area} />
    ),
  }),
  columnHelper.accessor("home_type_display", {
    header: "Home type",
  }),
  columnHelper.accessor("underwriting.str_revenue", {
    header: "STR Revenue",
    cell: (cell) => (
      <AsPrice value={cell.row.original.underwriting?.str_revenue} />
    ),
  }),
  columnHelper.accessor("underwriting.mtr_revenue", {
    header: "MTR Revenue",
    cell: (cell) => (
      <AsPrice value={cell.row.original.underwriting?.mtr_revenue} />
    ),
  }),
  columnHelper.accessor("underwriting.net_profit_monthly", {
    header: "Net profit/mo",
    cell: (cell) => (
      <AsPrice value={cell.row.original.underwriting?.net_profit_monthly} />
    ),
  }),
  columnHelper.accessor("underwriting.cash_on_cash_percent", {
    header: "Cash on cash %",
    cell: (cell) => (
      <AsPercent value={cell.row.original.underwriting?.cash_on_cash_percent} />
    ),
  }),
  columnHelper.accessor("underwriting.furnishing_medium", {
    header: "Furnishing cost",
    cell: (cell) => (
      <AsPrice value={cell.row.original.underwriting?.furnishing_medium} />
    ),
  }),
  columnHelper.accessor("contact.name", {
    header: "Contact name",
    enableResizing: true,
    cell: (cell) => (
      <MarketCampaignPropertyContactUpdate
        initialValue={cell.getValue()}
        field="name"
        propertyId={cell.row.original.id}
      />
    ),
  }),
  columnHelper.accessor("contact.email", {
    header: "Contact email",
    enableResizing: true,
    cell: (cell) => (
      <MarketCampaignPropertyContactUpdate
        initialValue={cell.getValue()}
        field="email"
        propertyId={cell.row.original.id}
      />
    ),
  }),
  columnHelper.accessor("contact.phone", {
    header: "Contact phone",
    enableResizing: true,
    cell: (cell) => (
      <MarketCampaignPropertyContactUpdate
        initialValue={cell.getValue()}
        field="phone"
        propertyId={cell.row.original.id}
      />
    ),
  }),
  columnHelper.accessor("contact.contact_status", {
    header: "Contact status",
    cell: (cell) => (
      cell.row.original.contact && (
        <MarketCampaignPropertyContactStatusToggle
          propertyId={cell.row.original.id}
          contact={cell.row.original.contact!}
        />
      )
    ),
  }),
  columnHelper.accessor("is_added_to_campaign_by_user", {
    header: "Added by",
    size: 130,
    cell: (cell) => (
      cell.row.original.is_added_to_campaign_by_user ? "User" : "Agent"
    ),
  }),
  columnHelper.display({
    header: "Submitted by",
    cell: (cell) => (
      <span>
        {cell.row.original.submitted_by ? cell.row.original.submitted_by.email : "N/A"}
      </span>
    ),
  }),
  columnHelper.display({
    header: "Landlord URL",
    cell: (cell) => (
      <ClipboardCell
        value={`${config.PUBLIC_URL}/landlords/verify-ownership/?hash=${cell.row.original.property_hash}`}
      />
    ),
  }),
  columnHelper.accessor("status_display", {
    header: "Status",
    cell: (cell) => (
      <MarketCampaignPropertyStatusToggle
        property={cell.row.original}
      />
    ),
  }),
  columnHelper.accessor("keyword_category", {
    header: "Keywords",
    size: 220,
  }),
  columnHelper.accessor("market_campaign_status_display", {
    header: "Market campaign status",
    size: 220,
  }),
  columnHelper.display({
    header: "Actions",
    cell: (cell) => (
      <div className="d-flex">
        <MarketCampaignPropertyContactConversationModal
          prop={cell.row.original}
        />
      </div>
    ),
  }),
];
