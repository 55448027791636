/** @jsxImportSource @emotion/react */
import React from "react";
import { useThemedCss } from "src/hooks/styles";
import type { SerializedStyles } from "@emotion/react";

import styles, { IStyles } from "./RadioGroup.styles";

type RadioOption = { label: string; value: string };

type RadioGroupProps = {
  name: string;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  options: RadioOption[];
  style?: React.CSSProperties;
  className?: string;
}

const forwardedRadioGroup = (
  {
    name,
    options,
    value,
    onChange,
    onBlur,
    style,
    className,
  }: RadioGroupProps,
  ref?: React.LegacyRef<HTMLInputElement>,
) => {
  const themedCss = useThemedCss<IStyles>(styles);

  const [internalValue, setInternalValue] = React.useState<string | undefined>(() => (value || "").toString());

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInternalValue(event.target.value);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div
      className={`btn-group ${className}`}
      css={themedCss.container}
      style={style}
    >
      {options.map((option) => (
        <label
          className={`btn ${internalValue === option.value ? "btn-primary" : "btn-outline-primary"}`}
          css={themedCss.button}
          style={internalValue === option.value ? { color: "white" } : {}}
          key={option.value}
          htmlFor={`${name}-${option.value}`}
        >
          <input
            ref={ref}
            type="radio"
            style={{ display: "none" }}
            value={option.value}
            id={`${name}-${option.value}`}
            onBlur={onBlur}
            onChange={handleChange}
            name={name}
            checked={internalValue === option.value}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default React.forwardRef<HTMLInputElement, RadioGroupProps>(forwardedRadioGroup);
