import React from "react";
import { useTranslation } from "react-i18next";
import type { FetchStatus } from "@tanstack/react-query";
import i18next from "i18next";
import { PropertyListDateFilter, PropertyType, PropertyUnderwritingEstimateType } from "src/enums/property";
import { formatNumber, formatPercent, formatPrice } from "src/utils/formatters/number";
import { STRRegulations } from "src/enums/str-regulations";
import isBoolean from "lodash/isBoolean";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import { useProfileStore } from "src/store/profile";

type IUsePropertyAddress = (
  property?: API.Property.PropertyListData | API.Property.PropertyDetailData | API.RevenueEstimations.AirbnbPredictionData,
) => string;

export const usePropertyAddress: IUsePropertyAddress = (property) => {
  const canAccessFullAddress = useProfileHasPermission(MembershipPermission.ACCESS_FULL_ADDRESS);
  const { isTrialPackage } = useProfileStore();

  return React.useMemo(() => {
    if (!property) {
      return "";
    }

    if (!canAccessFullAddress || isTrialPackage) {
      return `(Address hidden), ${property.market.name}, ${property.market_state.code}`;
    }

    return property.address;
  }, [property, isTrialPackage, canAccessFullAddress]);
};

export const usePropertyListTitle = (count: number, limit: number, fetchStatus: FetchStatus, verified: boolean = false) => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    const countString: string = count >= 100000 ? "100000+" : count.toString();
    if (fetchStatus === "fetching") {
      return t("common.property_list_loading", "Loading...") || "Loading...";
    }
    if (verified) {
      return t("common.property_verified_title", "Viewing approved properties") || "Viewing approved properties";
    }

    if (limit >= count) {
      return t("common.property_title_no_total", "Viewing {{count}} properties", { count }) || "Viewing {{count}} properties";
    }
    return (
      t("common.property_title", "Viewing {{limit}} of {{count}} properties", {
        limit,
        // @ts-ignore
        count: countString,
      }) || "Viewing {{limit}} of {{count}} properties"
    );
  }, [count, limit, fetchStatus, verified]);
};

type PropertyFilterTitleMap = {
  [key in keyof API.Property.PropertyFilter]: string;
};

export const PROPERTY_FILTER_TITLE_MAP: PropertyFilterTitleMap = {
  market: i18next.t("Area")?.toString() || "Area",
  markets: i18next.t("Areas")?.toString() || "Areas",
  property_area_min: i18next.t("Area(min)")?.toString() || "Area(min)",
  property_area_max: i18next.t("Area(max)")?.toString() || "Area(max)",
  home_type: i18next.t("Property type")?.toString() || "Property type",
  list_date_period: i18next.t("List date period")?.toString() || "List date period",
  rent_price_min: i18next.t("Rent price(min)")?.toString() || "Rent price(min)",
  rent_price_max: i18next.t("Rent price(max)")?.toString() || "Rent price(max)",
  bathrooms_min: i18next.t("Bathrooms")?.toString() || "Bathrooms",
  bedrooms_min: i18next.t("Bedrooms")?.toString() || "Bedrooms",
  location: i18next.t("Location")?.toString() || "Location",
  str_revenue_min: i18next.t("STR revenue(min)")?.toString() || "STR revenue(min)",
  str_revenue_max: i18next.t("STR revenue(max)")?.toString() || "STR revenue(max)",
  net_profit_monthly_min: i18next.t("Monthly net(min)")?.toString() || "Monthly net(min)",
  net_profit_monthly_max: i18next.t("Monthly net(max)")?.toString() || "Monthly net(max)",
  str_rating: i18next.t("STR rating")?.toString() || "STR rating",
  total_upfront_expenses_min: i18next.t("Upfront expenses(min)")?.toString() || "Upfront expenses(min)",
  total_upfront_expenses_max: i18next.t("Upfront expenses(max)")?.toString() || "Upfront expenses(max)",
  cash_on_cash_percent_min: i18next.t("Cash-on-cash(min)")?.toString() || "Cash-on-cash(min)",
  verified: i18next.t("Verified only")?.toString() || "Verified only",
  admin_status: i18next.t("Status")?.toString() || "Status",
  admin_search: i18next.t("Search")?.toString() || "Search",
  is_verified: i18next.t("Verified only")?.toString() || "Verified only",
  all_market_campaigns: i18next.t("My market campaigns")?.toString() || "My market campaigns",
  market_campaign: i18next.t("Market campaign")?.toString() || "Market campaign",
  revenue_monthly_percent: i18next.t("Management fee percent")?.toString() || "Management fee percent",
  revenue_monthly_percent_value_min: i18next.t("Management fee(min)")?.toString() || "Management fee(min)",
  revenue_monthly_percent_value_max: i18next.t("Management fee(max)")?.toString() || "Management fee(max)",
};

export const PROPERTY_FILTER_LIST_DATE_MAP = {
  [PropertyListDateFilter.LAST_30_DAYS]: i18next.t("Last 30 days")?.toString() || "Last 30 days",
  [PropertyListDateFilter.LAST_7_DAYS]: i18next.t("Last 7 days")?.toString() || "Last 7 days",
  [PropertyListDateFilter.LAST_24_HOURS]: i18next.t("Last 24 hours")?.toString() || "Last 24 hours",
};

export const PROPERTY_FILTER_PROPERTY_TYPE_MAP = {
  [PropertyType.SINGLE_FAMILY]: i18next.t("House")?.toString() || "House",
  [PropertyType.TOWNHOUSE]: i18next.t("Townhouse")?.toString() || "Townhouse",
  [PropertyType.APARTMENT]: i18next.t("Apartment")?.toString() || "Apartment",
  [PropertyType.OTHER]: i18next.t("Other")?.toString() || "Other",
};

export const PROPERTY_FILTER_STR_REGULATION = {
  [STRRegulations.POSITIVE]: i18next.t("Likely allowed")?.toString() || "Likely allowed",
  [STRRegulations.NEUTRAL]: i18next.t("Possibly allowed")?.toString() || "Possibly allowed",
  [STRRegulations.UNKNOWN]: i18next.t("More information needed")?.toString() || "More information needed",
  [STRRegulations.NEGATIVE]: i18next.t("Likely not allowed")?.toString() || "Likely not allowed",
};

type IUsePropertyFilterString = (filter: API.Property.PropertyFilter) => string;

const PERCENT_VALUES: string[] = ["cash_on_cash_percent_min", "revenue_monthly_percent"];
const PRICE_VALUES: string[] = [
  "rent_price_min",
  "rent_price_max",
  "str_revenue_min",
  "str_revenue_max",
  "net_profit_monthly_min",
  "net_profit_monthly_max",
  "total_upfront_expenses_min",
  "total_upfront_expenses_max",
  "revenue_monthly_percent_value_min",
  "revenue_monthly_percent_value_max",
];
const NUMBER_VALUES: string[] = ["property_area_min", "property_area_max"];
const NUMBER_MIN_VALUES: string[] = ["bathrooms_min", "bedrooms_min"];
const NO_VALUES = [
  "home_type",
  "str_rating",
  "location",
  "market",
  "markets",
  "verified",
  "admin_status",
  "admin_search",
  "all_market_campaigns",
  "market_campaign",
];

export type IUsePropertyFilterValue = (
  filterKey: keyof API.Property.PropertyFilter & string,
  value: string,
) => React.ReactNode | string | null;

export const usePropertyFilterValue: IUsePropertyFilterValue = (filterKey, value) =>
  React.useMemo(() => {
    if (!value || NO_VALUES.includes(filterKey)) {
      return null;
    }
    if (PRICE_VALUES.includes(filterKey)) {
      return formatPrice(value);
    }
    if (PERCENT_VALUES.includes(filterKey)) {
      return formatPercent(value);
    }
    if (NUMBER_VALUES.includes(filterKey)) {
      return formatNumber(value);
    }
    if (NUMBER_MIN_VALUES.includes(filterKey)) {
      let decimalPlaces = 0;
      if (filterKey === "bathrooms_min") {
        decimalPlaces = 1;
      }
      return `${formatNumber(value, decimalPlaces)}+`;
    }
    return null;
  }, [filterKey, value]);

export const usePropertyFilterString: IUsePropertyFilterString = (filter) =>
  React.useMemo(() => {
    if (!filter || Object.keys(filter).length === 0) {
      return "";
    }
    return Object.keys(filter)
      .reduce((filters: string[], key) => {
        if (filter[key] === undefined || filter[key] === null) {
          return filters;
        }
        if (PERCENT_VALUES.includes(key)) {
          return [...filters, `${PROPERTY_FILTER_TITLE_MAP[key] || key}: ${formatPercent(filter[key])}`];
        }
        if (PRICE_VALUES.includes(key)) {
          return [...filters, `${PROPERTY_FILTER_TITLE_MAP[key] || key}: ${formatPrice(filter[key])}`];
        }
        if (NUMBER_VALUES.includes(key)) {
          return [...filters, `${PROPERTY_FILTER_TITLE_MAP[key] || key}: ${filter[key]}`];
        }
        if (NO_VALUES.includes(key)) {
          return [...filters, `${PROPERTY_FILTER_TITLE_MAP[key] || key}`];
        }
        if (key === "list_date_period") {
          return [
            ...filters,
            // @ts-ignore
            `${PROPERTY_FILTER_TITLE_MAP[key] || key}: ${PROPERTY_FILTER_LIST_DATE_MAP[filter[key]]}`,
          ];
        }
        if (key === "property_type") {
          return [
            ...filters,
            // @ts-ignore
            `${PROPERTY_FILTER_TITLE_MAP[key] || key}: ${filter[key]
              .map(
                // @ts-ignore
                (type) => PROPERTY_FILTER_PROPERTY_TYPE_MAP[type],
              )
              .join(" | ")}`,
          ];
        }
        return [...filters, `${PROPERTY_FILTER_TITLE_MAP[key] || key}: ${filter[key]}`];
      }, [])
      .join(", ");
  }, [filter]);

export type IUsePropertyUnderwritingByEstimateReturn = Record<
  keyof PropertyUnderwritingEstimateType,
  API.Property.PropertyUnderwritingData
>;

export type IUsePropertyUnderwritingByEstimate = (
  underwritings: API.Property.PropertyUnderwritingData[],
) => IUsePropertyUnderwritingByEstimateReturn;

export const usePropertyUnderwritingByEstimate: IUsePropertyUnderwritingByEstimate = (underwritings) =>
  React.useMemo(
    () =>
      underwritings.reduce(
        (acc, underwriting) => ({
          ...acc,
          [underwriting.estimate]: underwriting,
        }),
        {} as IUsePropertyUnderwritingByEstimateReturn,
      ),
    [underwritings],
  );
