/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
    "container" | "button"
]>;

const styles: IStyles = {
  container: (theme) => css({}),
  button: (theme) => css({
    padding: theme.spaces.sm - 1,
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.fontSizes.base,
    lineHeight: theme.lineHeight.sm,
    whiteSpace: "nowrap",
    borderColor: theme.colorOutline,
    color: theme.colorTextPrimary,
    "&:hover": {
      color: theme.colorWhite,
      borderColor: theme.colorOutline,
    },
    [theme.mq.sm]: {
      fontSize: theme.fontSizes.sm,
    },
  }),
};

export default styles;
