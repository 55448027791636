/** @jsxImportSource @emotion/react */
import React from "react";
import Button from "src/components/ui/form/button";
import { useTranslation } from "react-i18next";
import { useBooleanState } from "src/hooks/boolean";
import { useThemedCss } from "src/hooks/styles";
import Form from "react-bootstrap/esm/Form";
import { ExpandableFormGroup } from "src/components/ui/form/group";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { PropertyListDateFilter, PropertyStatus, PropertyType } from "src/enums/property";
import Icon from "src/components/ui/icons";
import Text from "src/components/ui/text";
import RadioGroup from "src/components/ui/form/radio-group";
import styles, { IStyles } from "src/components/property/PropertyFilter.styles";
import { PROPERTY_FILTER_LIST_DATE_MAP, PROPERTY_FILTER_PROPERTY_TYPE_MAP, PROPERTY_FILTER_STR_REGULATION } from "src/hooks/property";
import { useBreakpointIsLowerOrEqual } from "src/hooks/responsive";
import Offcanvas from "react-bootstrap/esm/Offcanvas";
import { STRRegulations } from "src/enums/str-regulations";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import { Link, useLocation } from "react-router-dom";
import { useProfileMarketCampaignListRequest } from "src/hooks/api/profile";
import { useCoHostPercentStore } from "src/store/co-host-percent";
import { useTutorialStore } from "src/store/tutorial";

const positiveNumberError = "Value should be a positive number";

const schema = yup.object({
  home_type: yup.array().of(yup.string().oneOf(Object.keys(PROPERTY_FILTER_PROPERTY_TYPE_MAP))),
  list_date: yup.string().oneOf(Object.keys(PROPERTY_FILTER_LIST_DATE_MAP)),
  property_area_min: yup.number(),
  property_area_max: yup.number(),
  bedrooms_min: yup.string().oneOf(["1", "2", "3", "4", "5"]),
  bathrooms_min: yup.string().oneOf(["1", "2", "3", "4", "5"]),
  rent_price_min: yup.number().min(0, positiveNumberError),
  rent_price_max: yup.number().min(0, positiveNumberError),
  cash_on_cash_percent_min: yup.number().min(0, positiveNumberError),
  str_revenue_min: yup.number().min(0, positiveNumberError),
  str_revenue_max: yup.number().min(0, positiveNumberError),
  net_profit_monthly_min: yup.number().min(0, positiveNumberError),
  net_profit_monthly_max: yup.number().min(0, positiveNumberError),
  total_upfront_expenses_min: yup.number().min(0, positiveNumberError),
  total_upfront_expenses_max: yup.number().min(0, positiveNumberError),
  str_rating: yup.string().oneOf([STRRegulations.UNKNOWN, STRRegulations.NEGATIVE, STRRegulations.NEUTRAL, STRRegulations.POSITIVE]),
  admin_search: yup.string(),
  admin_status: yup.string(),
  revenue_monthly_percent: yup.string().oneOf(["10", "15", "20"]),
  revenue_monthly_percent_value_min: yup.number().min(0, positiveNumberError),
  revenue_monthly_percent_value_max: yup.number().min(0, positiveNumberError),
});

type FormFieldKeys = keyof API.Property.PropertyFilter;

const FORM_FIELD_KEYS: FormFieldKeys[] = [
  "home_type",
  "list_date_period",
  "all_market_campaigns",
  "market_campaign",
  "property_area_min",
  "property_area_max",
  "rent_price_min",
  "rent_price_max",
  "net_profit_monthly_min",
  "net_profit_monthly_max",
  "bedrooms_min",
  "bathrooms_min",
  "cash_on_cash_percent_min",
  "str_revenue_min",
  "str_revenue_max",
  "total_upfront_expenses_min",
  "total_upfront_expenses_max",
  "admin_search",
  "admin_status",
  "revenue_monthly_percent",
  "revenue_monthly_percent_value_min",
  "revenue_monthly_percent_value_max",
];

type FormValue = Pick<API.Property.PropertyFilter, FormFieldKeys>;

interface PropertyFilterProps {
  filter: API.Property.PropertyFilter;
  updateFilter: (filter: API.Property.PropertyFilter) => void;
  resetFilter: () => void;
  style?: React.CSSProperties;
}

const initialFormValue: FormValue = {
  home_type: [],
  list_date_period: "",
  all_market_campaigns: false,
  market_campaign: "",
  property_area_min: "",
  property_area_max: "",
  rent_price_min: "",
  rent_price_max: "",
  bedrooms_min: "",
  bathrooms_min: "",
  cash_on_cash_percent_min: "",
  str_revenue_min: "",
  str_revenue_max: "",
  net_profit_monthly_min: "",
  net_profit_monthly_max: "",
  total_upfront_expenses_min: "",
  total_upfront_expenses_max: "",
  revenue_monthly_percent: "",
  revenue_monthly_percent_value_min: "",
  revenue_monthly_percent_value_max: "",
  str_rating: [],
  admin_search: "",
  admin_status: "",
};

const PropertyFilterButton: React.FC<PropertyFilterProps> = ({ filter, updateFilter, resetFilter, style = {} }) => {
  const { t } = useTranslation();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const themedCss = useThemedCss<IStyles>(styles);
  const isMobile = useBreakpointIsLowerOrEqual("md");
  const canFilterProperties = useProfileHasPermission(MembershipPermission.ACCESS_PROPERTY_FILTER);
  const canUseAdminFilter = useProfileHasPermission(MembershipPermission.ACCESS_PROPERTY_ADMIN_FILTER);
  const location = useLocation();

  const { setReadyComponents, currentStepComponents } = useTutorialStore();

  React.useEffect(() => {
    setReadyComponents(["#property-filter-container", "#property-filter-toggle"]);
  }, []);

  const isCoHostPage = React.useMemo(() => location.pathname.startsWith("/co-host-properties"), [location.pathname]);

  const isArbitragePage = React.useMemo(() => location.pathname.startsWith("/arbitrage-properties"), [location.pathname]);

  const { coHostPercent } = useCoHostPercentStore();

  const { register, watch, setValue, resetField, reset } = useForm<FormValue>({
    defaultValues: initialFormValue,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    FORM_FIELD_KEYS.forEach((key) => {
      if (filter[key]) {
        setValue(key as string, filter[key]);
      } else {
        resetField(key as string);
      }
    });
  }, [filter]);

  const formValue = watch();

  const { state: isOpen, setFalse: closeDropdown, setTrue: openDropdown } = useBooleanState(false);

  React.useEffect(() => {
    if (currentStepComponents.includes("#property-filter-toggle") && !isOpen) {
      openDropdown();
    }
  }, [currentStepComponents, isOpen]);

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
    event.preventDefault();
    if (canFilterProperties) {
      const {
        revenue_monthly_percent: revenueMonthlyPercent,
        revenue_monthly_percent_value_min: revenueMonthlyPercentValueMin,
        revenue_monthly_percent_value_max: revenueMonthlyPercentValueMax,
        ...filterValue
      } = formValue;

      if (coHostPercent && (revenueMonthlyPercentValueMin || revenueMonthlyPercentValueMax)) {
        filterValue.revenue_monthly_percent = coHostPercent;
        if (revenueMonthlyPercentValueMin) {
          filterValue.revenue_monthly_percent_value_min = revenueMonthlyPercentValueMin;
        }
        if (revenueMonthlyPercentValueMax) {
          filterValue.revenue_monthly_percent_value_max = revenueMonthlyPercentValueMax;
        }
      }

      updateFilter(filterValue);
      closeDropdown();
    }

    return false;
  };

  const onReset: React.MouseEventHandler = (event) => {
    event.preventDefault();
    reset(initialFormValue);
    resetFilter();
    if (isMobile) {
      closeDropdown();
    }
  };

  const onOutsideClick = (event: any) => {
    if (isMobile) {
      return;
    }
    if (ref.current && !ref.current.contains(event.target) && isOpen) {
      if (canFilterProperties) {
        updateFilter(formValue);
      }
      closeDropdown();
    }
  };

  const onFilterToggle = React.useCallback(() => {
    if (isOpen) {
      if (canFilterProperties) {
        updateFilter(formValue);
      }
      closeDropdown();
    } else {
      openDropdown();
    }
  }, [isOpen, formValue]);

  React.useEffect(() => {
    document.addEventListener("click", onOutsideClick);
    return () => document.removeEventListener("click", onOutsideClick);
  }, [onOutsideClick]);

  const { data: marketCampaignsData } = useProfileMarketCampaignListRequest();

  const marketCampaigns = React.useMemo(() => {
    if (!marketCampaignsData?.data) {
      return [];
    }

    return marketCampaignsData.data;
  }, [marketCampaignsData]);

  const submitButton = React.useMemo(() => {
    if (canFilterProperties) {
      return (
        <Button
          type="submit"
          variant="primary"
          onClick={onSubmit}
          size={isMobile ? "sm" : undefined}
          css={themedCss.submitButton}
          tabIndex={10}
        >
          {t("Apply")}
        </Button>
      );
    }

    return (
      <>
        <Text color="muted" alignment="center" fontSize="7" className="mb-2">
          {t("This feature is not available for your membership package.")}
        </Text>
        <Link to="/membership/select/">
          <Button type="button" variant="primary" size={isMobile ? "sm" : undefined} css={themedCss.submitButton} tabIndex={10}>
            {t("Upgrade membership")}
          </Button>
        </Link>
      </>
    );
  }, [canFilterProperties, isMobile, onSubmit]);

  const dropdownContent = (
    <Form css={themedCss.dropdown} name="property-filter" onSubmit={onSubmit} noValidate>
      <div css={themedCss.heading}>
        <Text color="primary" className="text-nowrap">
          <Icon name="filter" className="me-2" variant="inherit" size={24} />
          {t("Filter by:")}
        </Text>
        <Text css={themedCss.clearButton} color="secondary" onClick={onReset}>
          <Icon name="cross" className="me-2" variant="secondary" size={24} />
          {t("Clear all")}
        </Text>
      </div>
      <div css={themedCss.scrollable}>
        {marketCampaigns.length > 0 && isArbitragePage && (
          <ExpandableFormGroup label={t("Market campaign")} isOpen={formValue.market_campaign || formValue.all_market_campaigns}>
            <Row>
              <Col xs={24} className="mb-3">
                <Form.Label>{t("Select market campaign")}</Form.Label>
                <Form.Select tabIndex={0} {...register("market_campaign")}>
                  <option value="">Select market campaign</option>
                  {marketCampaigns.map((campaign) => (
                    <option key={campaign.id} value={campaign.id}>
                      {campaign.market_name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={24}>
                <Form.Group>
                  <Form.Check.Input id="all_market_campaigns" className="me-2" {...register("all_market_campaigns")} />
                  <Form.Check.Label htmlFor="all_market_campaigns">{t("All market campaigns")}</Form.Check.Label>
                </Form.Group>
              </Col>
            </Row>
          </ExpandableFormGroup>
        )}

        <ExpandableFormGroup label={t("Rent price")} isOpen={formValue.rent_price_min || formValue.rent_price_max}>
          <Row>
            <Col xs={12}>
              <Form.Label>{t("Minimum")}</Form.Label>
              <Form.Control
                placeholder={t("No min") as string}
                inputMode="numeric"
                pattern="[0-9]"
                step="1"
                tabIndex={0}
                {...register("rent_price_min")}
              />
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>{t("Maximum")}</Form.Label>
                <Form.Control
                  placeholder={t("No max") as string}
                  inputMode="numeric"
                  pattern="[0-9]"
                  step="1"
                  tabIndex={0}
                  {...register("rent_price_max")}
                />
              </Form.Group>
            </Col>
          </Row>
        </ExpandableFormGroup>
        {isCoHostPage && (
          <ExpandableFormGroup
            label={t("Property management fee ({{percent}}%)", { percent: coHostPercent })}
            isOpen={formValue.revenue_monthly_percent_value_min || formValue.revenue_monthly_percent_value_max}
          >
            <Row>
              <Col xs={12}>
                <Form.Label>{t("Minimum")}</Form.Label>
                <Form.Control
                  placeholder={t("No min") as string}
                  inputMode="numeric"
                  pattern="[0-9]"
                  step="1"
                  tabIndex={0}
                  {...register("revenue_monthly_percent_value_min")}
                />
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>{t("Maximum")}</Form.Label>
                  <Form.Control
                    placeholder={t("No max") as string}
                    inputMode="numeric"
                    pattern="[0-9]"
                    step="1"
                    tabIndex={0}
                    {...register("revenue_monthly_percent_value_max")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </ExpandableFormGroup>
        )}
        {!isCoHostPage && (
          <ExpandableFormGroup label={t("Monthly net")} isOpen={formValue.net_profit_monthly_min || formValue.net_profit_monthly_max}>
            <Row>
              <Col xs={12}>
                <Form.Label>{t("Minimum")}</Form.Label>
                <Form.Control
                  placeholder={t("No min") as string}
                  inputMode="numeric"
                  pattern="[0-9]"
                  step="1"
                  tabIndex={0}
                  {...register("net_profit_monthly_min")}
                />
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>{t("Maximum")}</Form.Label>
                  <Form.Control
                    placeholder={t("No max") as string}
                    inputMode="numeric"
                    pattern="[0-9]"
                    step="1"
                    tabIndex={0}
                    {...register("net_profit_monthly_max")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </ExpandableFormGroup>
        )}
        <ExpandableFormGroup label={t("Cash-on-cash return")} isOpen={formValue.cash_on_cash_percent_min}>
          <Row>
            <Col xs={24}>
              <Form.Label>{t("Minimum")}</Form.Label>
              <Form.Control
                placeholder={t("Min %") as string}
                inputMode="numeric"
                pattern="[0-9]"
                step="1"
                tabIndex={1}
                {...register("cash_on_cash_percent_min")}
              />
            </Col>
          </Row>
        </ExpandableFormGroup>
        <ExpandableFormGroup label={t("Monthly revenue")} isOpen={formValue.str_revenue_min || formValue.str_revenue_max}>
          <Row>
            <Col xs={12}>
              <Form.Label>{t("Minimum")}</Form.Label>
              <Form.Control
                placeholder={t("No min") as string}
                inputMode="numeric"
                pattern="[0-9]"
                step="1"
                tabIndex={2}
                {...register("str_revenue_min")}
              />
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>{t("Maximum")}</Form.Label>
                <Form.Control
                  placeholder={t("No max") as string}
                  inputMode="numeric"
                  pattern="[0-9]"
                  step="1"
                  tabIndex={2}
                  {...register("str_revenue_max")}
                />
              </Form.Group>
            </Col>
          </Row>
        </ExpandableFormGroup>
        <ExpandableFormGroup label={t("Property type")} isOpen={formValue.home_type.length > 0}>
          <div css={themedCss.checkboxButtonGroup}>
            <Form.Check id={`property-type-${PropertyType.SINGLE_FAMILY}`} css={themedCss.checkboxButton}>
              <Form.Check.Input
                css={themedCss.checkboxButtonInput}
                type="checkbox"
                value={PropertyType.SINGLE_FAMILY}
                {...register("home_type")}
              />
              <Form.Check.Label css={themedCss.checkboxButtonLabel}>
                {PROPERTY_FILTER_PROPERTY_TYPE_MAP[PropertyType.SINGLE_FAMILY]}
              </Form.Check.Label>
            </Form.Check>
            <Form.Check id={`property-type-${PropertyType.TOWNHOUSE}`} css={themedCss.checkboxButton}>
              <Form.Check.Input
                css={themedCss.checkboxButtonInput}
                type="checkbox"
                value={PropertyType.TOWNHOUSE}
                {...register("home_type")}
              />
              <Form.Check.Label css={themedCss.checkboxButtonLabel}>
                {PROPERTY_FILTER_PROPERTY_TYPE_MAP[PropertyType.TOWNHOUSE]}
              </Form.Check.Label>
            </Form.Check>
            <Form.Check id={`property-type-${PropertyType.APARTMENT}`} css={themedCss.checkboxButton}>
              <Form.Check.Input
                css={themedCss.checkboxButtonInput}
                type="checkbox"
                value={PropertyType.APARTMENT}
                {...register("home_type")}
              />
              <Form.Check.Label css={themedCss.checkboxButtonLabel}>
                {PROPERTY_FILTER_PROPERTY_TYPE_MAP[PropertyType.APARTMENT]}
              </Form.Check.Label>
            </Form.Check>
            <Form.Check id={`property-type-${PropertyType.OTHER}`} css={themedCss.checkboxButton}>
              <Form.Check.Input css={themedCss.checkboxButtonInput} type="checkbox" value={PropertyType.OTHER} {...register("home_type")} />
              <Form.Check.Label css={themedCss.checkboxButtonLabel}>
                {PROPERTY_FILTER_PROPERTY_TYPE_MAP[PropertyType.OTHER]}
              </Form.Check.Label>
            </Form.Check>
          </div>
        </ExpandableFormGroup>
        <ExpandableFormGroup label={t("STR Regulation")} isOpen={formValue.str_rating.length > 0}>
          <Form.Check
            label={
              <span className="d-flex align-items-center justify-content-start">
                {PROPERTY_FILTER_STR_REGULATION[STRRegulations.POSITIVE]}
                <Icon name="note-pen" variant="success" className="ms-1" />
              </span>
            }
            value={STRRegulations.POSITIVE}
            {...register("str_rating")}
          />
          <Form.Check
            label={
              <span className="d-flex align-items-center justify-content-start">
                {PROPERTY_FILTER_STR_REGULATION[STRRegulations.NEUTRAL]}
                <Icon name="note-pen" variant="warning" className="ms-1" />
              </span>
            }
            value={STRRegulations.NEUTRAL}
            {...register("str_rating")}
          />
          <Form.Check
            label={
              <span className="d-flex align-items-center justify-content-start">
                {PROPERTY_FILTER_STR_REGULATION[STRRegulations.UNKNOWN]}
                <Icon name="note-pen" variant="default" className="ms-1" />
              </span>
            }
            value={STRRegulations.UNKNOWN}
            {...register("str_rating")}
          />
          <Form.Check
            label={
              <span className="d-flex align-items-center justify-content-start">
                {PROPERTY_FILTER_STR_REGULATION[STRRegulations.NEGATIVE]}
                <Icon name="note-pen" variant="danger" className="ms-1" />
              </span>
            }
            value={STRRegulations.NEGATIVE}
            {...register("str_rating")}
          />
        </ExpandableFormGroup>
        <ExpandableFormGroup
          label={t("Upfront expenses")}
          isOpen={formValue.total_upfront_expenses_min || formValue.total_upfront_expenses_max}
        >
          <Row>
            <Col xs={12}>
              <Form.Label>{t("Minimum")}</Form.Label>
              <Form.Control
                placeholder={t("No min") as string}
                inputMode="numeric"
                pattern="[0-9]"
                step="1"
                tabIndex={3}
                {...register("total_upfront_expenses_min")}
              />
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>{t("Maximum")}</Form.Label>
                <Form.Control
                  placeholder={t("No max") as string}
                  inputMode="numeric"
                  pattern="[0-9]"
                  step="1"
                  tabIndex={3}
                  {...register("total_upfront_expenses_max")}
                />
              </Form.Group>
            </Col>
          </Row>
        </ExpandableFormGroup>
        <ExpandableFormGroup label={t("List date")} isOpen={formValue.list_date_period}>
          <Form.Select tabIndex={4} {...register("list_date_period")}>
            <option value="">{t("Any")}</option>
            <option value={PropertyListDateFilter.LAST_30_DAYS}>
              {PROPERTY_FILTER_LIST_DATE_MAP[PropertyListDateFilter.LAST_30_DAYS]}
            </option>
            <option value={PropertyListDateFilter.LAST_7_DAYS}>{PROPERTY_FILTER_LIST_DATE_MAP[PropertyListDateFilter.LAST_7_DAYS]}</option>
            <option value={PropertyListDateFilter.LAST_24_HOURS}>
              {PROPERTY_FILTER_LIST_DATE_MAP[PropertyListDateFilter.LAST_24_HOURS]}
            </option>
          </Form.Select>
        </ExpandableFormGroup>
        <ExpandableFormGroup label={t("Number of Bedrooms")} isOpen={formValue.bedrooms_min}>
          <RadioGroup
            options={[
              { label: t("Any"), value: "" },
              { label: t("1+"), value: "1" },
              { label: t("2+"), value: "2" },
              { label: t("3+"), value: "3" },
              { label: t("4+"), value: "4" },
              { label: t("5+"), value: "5" },
            ]}
            {...register("bedrooms_min")}
          />
        </ExpandableFormGroup>
        <ExpandableFormGroup label={t("Number of Bathrooms")} isOpen={formValue.bathrooms_min}>
          <RadioGroup
            options={[
              { label: t("Any"), value: "" },
              { label: t("1+"), value: "1" },
              { label: t("1.5+"), value: "1.5" },
              { label: t("2+"), value: "2" },
              { label: t("3+"), value: "3" },
              { label: t("4+"), value: "4" },
            ]}
            {...register("bathrooms_min")}
          />
        </ExpandableFormGroup>
        <ExpandableFormGroup label={t("SQFT")} isOpen={formValue.property_area_min || formValue.property_area_max}>
          <Row>
            <Col xs={12}>
              <Form.Label>{t("Minimum")}</Form.Label>
              <Form.Control
                placeholder={t("No min") as string}
                inputMode="numeric"
                pattern="[0-9]"
                step="1"
                tabIndex={5}
                {...register("property_area_min")}
              />
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>{t("Maximum")}</Form.Label>
                <Form.Control
                  placeholder={t("No max") as string}
                  inputMode="numeric"
                  pattern="[0-9]"
                  step="1"
                  tabIndex={5}
                  {...register("property_area_max")}
                />
              </Form.Group>
            </Col>
          </Row>
        </ExpandableFormGroup>
        {canUseAdminFilter && (
          <ExpandableFormGroup label={t("Admin filters")} isOpen={formValue.admin_search || formValue.admin_status}>
            <Row>
              <Col xs={24}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Search by external ID or URL")}</Form.Label>
                  <Form.Control placeholder={t("Enter Zillow ID or URL") as string} {...register("admin_search")} />
                </Form.Group>
              </Col>
              <Col xs={24}>
                <Form.Group>
                  <Form.Label>{t("Filter by status")}</Form.Label>
                  <Form.Select {...register("admin_status")}>
                    <option value="">Any</option>
                    <option value={PropertyStatus.VERIFIED}>Verified</option>
                    <option value={PropertyStatus.LISTED}>Listed</option>
                    <option value={PropertyStatus.NOT_EXTERNALLY_LISTED}>Not on Zillow</option>
                    <option value={PropertyStatus.OUT_OF_BOUNDS}>Out of bounds</option>
                    <option value={PropertyStatus.NEEDS_VERIFICATION}>Needs verification</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </ExpandableFormGroup>
        )}
      </div>
      {!isMobile && <div css={themedCss.footer}>{submitButton}</div>}
    </Form>
  );

  return (
    <div id="property-filter-container" ref={ref} css={themedCss.container} style={style}>
      <Button
        icon={<Icon name="filter" variant="inherit" size={24} />}
        variant="outline-primary"
        css={themedCss.button}
        onClick={onFilterToggle}
        id="property-filter-toggle"
      >
        {t("Filter")}
      </Button>

      {!isMobile && isOpen && dropdownContent}

      {isMobile && (
        <Offcanvas show={isOpen} onHide={closeDropdown} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="d-flex w-100 justify-content-between">
              {t("Filter properties")}
              {submitButton}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>{dropdownContent}</Offcanvas.Body>
        </Offcanvas>
      )}
    </div>
  );
};

export default PropertyFilterButton;
