import create from "zustand";
import { persist } from "zustand/middleware";

interface ICoHostPercentStore {
  coHostPercent: API.Property.PropertyCoHostNetProfitPercent;
  setCoHostPercent: (percent: API.Property.PropertyCoHostNetProfitPercent) => void;
}

export const useCoHostPercentStore = create<ICoHostPercentStore>()(
  persist(
    (set, get) => ({
      coHostPercent: "10",
      setCoHostPercent: (percent) => set({ coHostPercent: percent }),
    }),
    {
      name: "coHostPercentStore",
    },
  ),
);
