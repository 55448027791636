import React from "react";
import FormSelect from "react-bootstrap/esm/FormSelect";
import { useToastStore } from "src/store/toast";
import { useUpdatePropertyContactRequest } from "src/hooks/api/admin/property";

interface MarketCampaignPropertyContactStatusToggleProps {
  contact: AdminAPI.Property.PropertyContactData;
  propertyId: number | string;
}

const MarketCampaignPropertyContactStatusToggle: React.FC<MarketCampaignPropertyContactStatusToggleProps> = ({ propertyId, contact }) => {
  const { addToastSuccess, addToastDanger } = useToastStore();
  const [status, setStatus] = React.useState(() => contact.contact_status);
  const { data, mutate, error, isLoading } = useUpdatePropertyContactRequest(propertyId);

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    setStatus(event.target.value as AdminAPI.Property.PropertyContactData["contact_status"]);
    mutate({
      business_name: "",
      email: contact.email,
      phone: contact.phone !== null ? contact.phone : "",
      name: contact.name,
      contact_status: event.target.value as AdminAPI.Property.PropertyContactStatus,
    });
  };

  React.useEffect(() => {
    if (data?.data?.contact_status) {
      addToastSuccess({
        title: `Contact status for ${data.data.name} changed to ${data.data.contact_status_display}`,
      });
      setStatus(data.data.contact_status);
    }
  }, [data]);

  React.useEffect(() => {
    if (error?.response?.data?.detail) {
      addToastDanger({
        title: error.response.data.detail,
      });
    }
  }, [error]);

  return (
    <FormSelect value={status} onChange={handleChange} disabled={isLoading}>
      <option value="">All</option>
      <option value="not_set">Not set</option>
      <option value="expressed_interest">Expressed interest</option>
      <option value="no_answer">No Answer</option>
      <option value="not_interested">Not Interested</option>
      <option value="warm_lead">Warm Lead</option>
      <option value="lead">Lead</option>
      <option value="leased">Leased</option>
    </FormSelect>
  );
};

export default MarketCampaignPropertyContactStatusToggle;
