import React from "react";
import {
  ColumnResizeMode,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  RowSelectionState,
  TableState,
  useReactTable,
} from "@tanstack/react-table";
import { usePropertyTableAdminRequest, usePropertyTableAdminExportRequest } from "src/hooks/api/admin/property";
import { useLeadToolGHLSendContactRequest, useLeadToolLeadWorkflowRequest } from "src/hooks/api/admin/leadtool";
import { useLimitOffsetPaginationParams, useParamPageNumber } from "src/hooks/pagination";
import Text from "src/components/ui/text";
import defaultColumns, {
  createActionsColumn,
  defaultColumn,
  keywordVerificationColumns,
} from "src/pages/admin/properties/table-page/columns";
import TableHeaderGroup from "src/pages/admin/properties/table-page/components/TableHeaderGroup";
import { LimitOffsetPagination } from "src/components/ui/pagination";
import { CenterPreloader } from "src/components/loaders";
import Form from "react-bootstrap/esm/Form";
import { useNavigate } from "react-router-dom";
import { useQueryFilter, useSearchParamsString } from "src/hooks/search-params";
import PropertyTableFilterContext from "src/pages/admin/properties/table-page/context";
import PropertyTableFilter from "src/pages/admin/properties/table-page/components/PropertyTableFilter";
import Button from "src/components/ui/form/button";
import { useBooleanState } from "src/hooks/boolean";
import Modal from "react-bootstrap/esm/Modal";
import { useToastStore } from "src/store/toast";

const TABLE_STATE_KEY = "admin-property-table-state";
const TABLE_PAGINATION_STATE_KEY = "admin-property-table-pagination";
type LimitValue = 10 | 25 | 50 | 100 | 200;

const defaultKeywordValidationFilter = {
  has_description: true,
  is_keyword_category_verified: false,
  keyword_categories: ["arbitrage", "co_host"],
} as AdminAPI.Property.PropertyTableFilter;

interface PropertyTablePageProps {
  keywordValidationOnly?: boolean;
  pageTitle?: string;
  baseUrl?: string;
  overrideFilter?: Partial<AdminAPI.Property.PropertyTableFilter>;
  allowExport?: boolean;
}

const PropertyTablePage: React.FC<PropertyTablePageProps> = ({
  keywordValidationOnly,
  pageTitle,
  overrideFilter,
  allowExport,
  baseUrl = "/admin/properties/table/",
}) => {
  const pageId = useParamPageNumber();
  const searchParams = useSearchParamsString();
  const [limit, setLimit] = React.useState<LimitValue>(() => {
    if (window.localStorage.getItem(TABLE_PAGINATION_STATE_KEY)) {
      const state = parseInt(window.localStorage.getItem(TABLE_PAGINATION_STATE_KEY)!, 10);
      if (!Number.isNaN(state) && [10, 25, 50, 100, 200].includes(state)) {
        return state as LimitValue;
      }
    }
    return 25;
  });
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const navigate = useNavigate();
  const paginationParams = useLimitOffsetPaginationParams(limit);
  const title = React.useMemo(() => {
    if (pageTitle) {
      return pageTitle;
    }

    return "Property table";
  }, [pageTitle]);

  const filter = useQueryFilter<AdminAPI.Property.PropertyTableFilter>({});

  const requestParams = React.useMemo(() => {
    if (keywordValidationOnly) {
      return {
        ...paginationParams,
        filter: JSON.stringify({
          ...{
            keyword_category: filter.filter?.keyword_category,
          },
          ...defaultKeywordValidationFilter,
        }),
      };
    }

    let filterParams = filter.filter || {};
    if (overrideFilter) {
      filterParams = {
        ...filterParams,
        ...overrideFilter,
      };
    }
    return {
      ...paginationParams,
      filter: Object.keys(filterParams).length !== 0 ? JSON.stringify(filterParams) : undefined,
    };
  }, [paginationParams, filter.filter, overrideFilter, keywordValidationOnly]);

  const { addToastSuccess, addToastDanger } = useToastStore();

  const {
    data: propertyData,
    fetchStatus: propertyFetchStatus,
    refetch: refetchPropertyData,
  } = usePropertyTableAdminRequest(requestParams);

  React.useEffect(() => {
    if (pageId === -1 || propertyData?.data?.count === undefined) {
      return;
    }
    if (pageId >= propertyData.data.count / paginationParams.limit) {
      navigate(`${baseUrl}${searchParams}`, {
        replace: true,
      });
    }
  }, [pageId, propertyData?.data?.count, paginationParams.limit, baseUrl, searchParams]);

  const columns = React.useMemo<typeof defaultColumns>(() => {
    if (keywordValidationOnly) {
      return keywordVerificationColumns;
    }
    return [createActionsColumn(refetchPropertyData), ...defaultColumns];
  }, [keywordValidationOnly]);

  const handlePerPageChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    event.preventDefault();
    const value = parseInt(event.target.value, 10);
    if (!Number.isNaN(value)) {
      setLimit(value as LimitValue);
      window.localStorage.setItem(TABLE_PAGINATION_STATE_KEY, value.toString());
      navigate(baseUrl);
    }
  };

  const properties = React.useMemo(() => propertyData?.data?.results || [], [propertyData?.data, keywordValidationOnly]);

  const table = useReactTable<AdminAPI.Property.PropertyTableData>({
    data: properties,
    columns,
    defaultColumn,
    columnResizeMode: "onChange" as ColumnResizeMode,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    enableMultiRowSelection: true,
    getRowId: (row) => row.id.toString(),
  });

  const [state, onStateChange] = React.useState<TableState>(() => {
    try {
      if (window.localStorage.getItem(TABLE_STATE_KEY)) {
        return JSON.parse(window.localStorage.getItem(TABLE_STATE_KEY)!);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    return table.initialState;
  });

  React.useEffect(() => {
    window.localStorage.setItem(TABLE_STATE_KEY, JSON.stringify(state));
  }, [state]);

  table.setOptions((prev) => ({
    ...prev,
    state: {
      ...state,
      rowSelection,
    },
    onStateChange,
    onRowSelectionChange: setRowSelection,
  }));

  const tableResponsiveClassName = React.useMemo(
    () =>
      propertyFetchStatus === "fetching"
        ? "table-responsive position-relative table-responsive--loading"
        : "table-responsive position-relative",
    [propertyFetchStatus],
  );

  const containerRef = React.useRef<HTMLDivElement>(null);
  const tableHeaderRef = React.useRef<HTMLTableElement>(null);
  const tableContainerRef = React.useRef<HTMLTableElement>(null);
  const tableRef = React.useRef<HTMLTableElement>(null);

  React.useEffect(() => {
    if (!tableContainerRef.current || !containerRef.current) {
      return () => {};
    }
    const handleDocumentScroll = (event: any) => {
      if (!tableRef.current || !containerRef.current) {
        return;
      }

      const { left: containerLeft } = containerRef.current!.getBoundingClientRect();
      const { left: tableLeft } = tableRef.current!.getBoundingClientRect();

      window.requestAnimationFrame(() => {
        if (tableHeaderRef.current) {
          tableHeaderRef.current.style.transform = `translateX(${tableLeft - containerLeft}px)`;
        }
      });
    };

    tableContainerRef.current.addEventListener("scroll", handleDocumentScroll, { capture: false });
    return () => {
      if (!tableContainerRef.current) {
        return;
      }

      tableContainerRef.current.removeEventListener("scroll", handleDocumentScroll);
    };
  }, [tableRef.current, tableHeaderRef.current]);

  const {
    mutate: mutateExport,
    isLoading: isExporting,
    isSuccess: isExportSuccess,
    data: exportData,
  } = usePropertyTableAdminExportRequest();

  const handleGetCSV = () => {
    mutateExport(requestParams);
  };

  const {
    mutate: mutateSendToGHL,
    isLoading: isSendToGHLLoading,
    isSuccess: isSendToGHLSuccess,
    status: sendToGHLStatus,
    data: sendToGHLData,
    error: sendToGHLError,
  } = useLeadToolGHLSendContactRequest();

  const {
    mutate: mutateLeadWorkflow,
    isLoading: isLeadWorkflowLoading,
    isSuccess: isLeadWorkflowSuccess,
    status: leadWorkflowStatus,
    data: leadWorkflowData,
    error: leadWorkflowError,
  } = useLeadToolLeadWorkflowRequest();

  const handleSendToGHL = () => {
    mutateSendToGHL({
      property_ids: Object.keys(rowSelection).map((id) => parseInt(id, 10)),
    });
  };

  const handleLeadWorkflow = () => {
    mutateLeadWorkflow({
      property_ids: Object.keys(rowSelection).map((id) => parseInt(id, 10)),
    });
  };

  const { state: isModalShow, setTrue: showModal, setFalse: hideModal } = useBooleanState(false);

  React.useEffect(() => {
    if (isExportSuccess && exportData?.data) {
      const url = exportData.data.file;

      try {
        const newTab = window.open(url, "_blank");

        if (!newTab || newTab.closed || typeof newTab.closed === "undefined") {
          showModal();
        }
      } catch (e) {
        showModal();
      }
    }
  }, [exportData, isExportSuccess]);

  React.useEffect(() => {
    if (sendToGHLStatus === "success") {
      addToastSuccess({
        title: "Properties sent to GHL successfully",
      });
    } else if (sendToGHLStatus === "error") {
      addToastDanger({
        title: `Error sending properties to GHL: ${sendToGHLError.response?.data?.message}`,
      });
    }
  }, [sendToGHLStatus]);

  React.useEffect(() => {
    if (leadWorkflowStatus === "success") {
      addToastSuccess({
        title: `Lead workflow started successfully for ${leadWorkflowData.data.tracer_task} id`,
      });
    } else if (leadWorkflowStatus === "error") {
      addToastDanger({
        title: `Error starting lead workflow: ${leadWorkflowError.response?.data?.message}`,
      });
    }
  }, [leadWorkflowStatus]);

  return (
    <div className="position-relative" ref={containerRef}>
      <div className="d-flex justify-content-between mb-3">
        <Text.Title level="3" className="mb-4" alignment="start">
          {title}
        </Text.Title>
        <div className="d-flex gap-2">
          {allowExport && (
            <Button
              onClick={handleLeadWorkflow}
              variant="primary"
              size="sm"
              className="text-nowrap px-4 py-2 h-10"
              loading={isLeadWorkflowLoading}
            >
              Lead Workflow
            </Button>
          )}
          {allowExport && (
            <Button
              onClick={handleSendToGHL}
              variant="primary"
              size="sm"
              className="text-nowrap px-4 py-2 h-10"
              loading={isSendToGHLLoading}
            >
              Send to GHL
            </Button>
          )}
          {allowExport && (
            <Button onClick={handleGetCSV} variant="primary" size="sm" className="text-nowrap px-4 py-2 h-10" loading={isExporting}>
              Export to CSV
            </Button>
          )}
        </div>
      </div>
      <PropertyTableFilterContext.Provider value={filter}>
        <PropertyTableFilter onlyState={keywordValidationOnly} />
      </PropertyTableFilterContext.Provider>
      <div ref={tableContainerRef} className="sticky top-0 z-10 flex flex-col h-[calc(100vh-90px)] overflow-auto customScrollbar2">
        {propertyFetchStatus === "fetching" && (
          <div className="table-responsive__loader">
            <CenterPreloader />
          </div>
        )}

        <table
          ref={tableRef}
          className="table table-group-divider table-bordered av-table-ellipsis"
          style={{
            width: table.getCenterTotalSize(),
            tableLayout: "fixed", // Ensures the table layout is fixed
          }}
        >
          <thead
            style={{
              position: "sticky",
              top: 0,
              background: "#fff",
              zIndex: 10,
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <TableHeaderGroup key={headerGroup.id} headerGroup={headerGroup} />
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    <div className="av-table-ellipsis__cell max-h-[100px] overflow-y-auto overflow-auto customScrollbar2">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        <div className="d-flex justify-content-center flex-nowrap pt-4">
          <LimitOffsetPagination pageSize={paginationParams.limit} total={propertyData?.data?.count || 0} baseUrl={baseUrl} />
          <div className="d-flex align-items-center flex-nowrap text-nowrap ms-4">
            Per page:
            <Form.Select onChange={handlePerPageChange} value={limit} className="ms-2">
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </Form.Select>
          </div>
        </div>
      </div>

      <Modal show={isModalShow} onHide={hideModal}>
        <Modal.Header closeButton onHide={hideModal} />
        <Modal.Body>
          <p>The popup was blocked by the browser. Use this button to download the file.</p>
        </Modal.Body>
        <Modal.Footer>
          <a href={exportData?.data?.file || ""} target="_blank" rel="noreferrer">
            <Button variant="primary">Download</Button>
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PropertyTablePage;
