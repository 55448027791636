"use client";

import React, { useMemo, useState } from "react";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { Textarea } from "src/components/ui/textarea";
import { Plus, Minus, Upload, Info } from "lucide-react";
import AirvanaTooltip from "src/components/ui/AirvanaTooltips";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "src/components/ui/dialog";
import { v4 as uuidv4 } from "uuid";

interface UrlInputFormProps {
  urls?: string[];
  onUrlsChange: (urls: string[]) => void;
}

export function UrlInputForm({ urls = [], onUrlsChange }: UrlInputFormProps) {
  const [bulkUrls, setBulkUrls] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const urlIds = useMemo(() => urls.map(() => uuidv4()), [urls.length]);

  const addUrl = () => {
    const newUrls = [...urls, ""];
    onUrlsChange(newUrls);
  };

  const removeUrl = (index: number) => {
    const newUrls = urls.filter((_, i) => i !== index);
    onUrlsChange(newUrls);
  };

  const updateUrl = (index: number, value: string) => {
    const newUrls = urls.map((url, i) => (i === index ? value : url));
    onUrlsChange(newUrls);
  };

  const handleBulkUrlsParse = () => {
    const parsedUrls = bulkUrls
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url !== "");
    const newUrls = [...urls, ...parsedUrls];
    onUrlsChange(newUrls);
    setBulkUrls("");
    setIsDialogOpen(false);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center flex-wrap gap-2">
        <div className="flex items-center gap-2">
          <Label htmlFor="urls" className="text-xs tracking-normal">
            Site URLs
          </Label>
          <AirvanaTooltip value="Enter the URLs of the sites you want to monitor" key="MonitorSiteURLs">
            <Info className="w-3 h-3" />
          </AirvanaTooltip>
        </div>
        <div className="flex gap-1 items-center justify-center">
          <Button type="button" variant="outline" onClick={addUrl} size="sm" className="text-xs tracking-normal px-2">
            <Plus className="h-3 w-3 mr-2" />
            Add URL
          </Button>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button type="button" variant="outline" onClick={addUrl} size="sm" className="text-xs tracking-normal px-2">
                <Upload className="h-3 w-3 mr-2" />
                Multiple URLs
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Multiple URLs</DialogTitle>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <Textarea placeholder="Enter one URL per line" value={bulkUrls} onChange={(e) => setBulkUrls(e.target.value)} rows={10} />
                <Button onClick={handleBulkUrlsParse}>Parse URLs</Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="max-h-[200px] overflow-y-auto space-y-2 customScrollbar2">
        {urls.map((url, index) => (
          <div key={urlIds[index]} className="flex items-center space-x-2">
            <Input value={url} onChange={(e) => updateUrl(index, e.target.value)} placeholder="Enter URL" className="text-xs" autoComplete="off" />
            <Button type="button" variant="outline" size="icon" onClick={() => removeUrl(index)} aria-label="Remove URL">
              <Minus className="h-4 w-4" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
