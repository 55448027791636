"use client";

import React, { useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { Info } from "lucide-react";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import AirvanaTooltip from "src/components/ui/AirvanaTooltips";

interface DateRestrictFormProps {
  onDateRestrictChange: (dateRestrict: string) => void;
  load: boolean;
  setLoad: (load: boolean) => void;
  initialDateRestrict?: string;
}

export default function DateRestrictForm({ initialDateRestrict, onDateRestrictChange, load = true, setLoad }: DateRestrictFormProps) {
  // Parse initial date restrict into timeUnit and quantity if provided
  const [timeUnit, setTimeUnit] = useState<string>("y");
  const [quantity, setQuantity] = useState<string>("1");

  React.useEffect(() => {
    // Ensure quantity is at least 1
    if (load) {
      setLoad(false);
      return;
    }
    if (quantity.length > 0 && parseInt(quantity, 10) < 1) {
      setQuantity("1");
      return; // Exit early to prevent double updates
    }
    // Only trigger onChange if we have valid values and they're different from initial
    if (timeUnit && quantity) {
      const newDateRestrict = `${timeUnit}${quantity}`;
      onDateRestrictChange(newDateRestrict);
    }
  }, [timeUnit, quantity, onDateRestrictChange]);

  React.useEffect(() => {
    if (load) {
      setTimeUnit(initialDateRestrict?.slice(0, 1) || "d");
      setQuantity(initialDateRestrict?.slice(1) || "1");
    }
  }, [load]);

  return (
    <div className="grid grid-cols-2 gap-3">
      <div>
        <div className="flex items-center gap-2">
          <Label htmlFor="quantity" className="text-xs">
            Time Amount
          </Label>
          <AirvanaTooltip value="Number of time units to return" key="MonitorDateRestrictTimeAmount">
            <Info className="w-3 h-3" />
          </AirvanaTooltip>
        </div>
        <Input
          id="quantity"
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          min="1"
          className="text-xs"
          autoComplete="off"
        />
      </div>
      <div>
        <div className="flex items-center gap-2">
          <Label htmlFor="timeUnit" className="text-xs">
            Time Unit
          </Label>
          <AirvanaTooltip value="Time unit to monitor" key="MonitorDateRestrictTimeUnit">
            <Info className="w-3 h-3" />
          </AirvanaTooltip>
        </div>
        <Select value={timeUnit} onValueChange={setTimeUnit}>
          <SelectTrigger id="timeUnit">
            <SelectValue placeholder="Select time unit to monitor" className="text-xs" />
          </SelectTrigger>
          <SelectContent className="z-[9999]">
            <SelectItem value="h" className="text-xs">
              Hours
            </SelectItem>
            <SelectItem value="d" className="text-xs">
              Days
            </SelectItem>
            <SelectItem value="w" className="text-xs">
              Weeks
            </SelectItem>
            <SelectItem value="m" className="text-xs">
              Months
            </SelectItem>
            <SelectItem value="y" className="text-xs">
              Years
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
