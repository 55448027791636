import React, { ReactNode, useState } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";

const AirvanaTooltip = ({ children, value }: { children: ReactNode; value: string }) => {
  const [open, setOpen] = useState(false);

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={600} disableHoverableContent={true as boolean} open={open} onOpenChange={(e) => setOpen(e)}>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="backdrop-blur-sm bg-black/80 text-white text-[10px] font-medium max-w-[210px] data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-[4px] px-2 py-1 text-font-active z-[1000] text-xs"
            sideOffset={5}
            side="bottom"
          >
            {value}
            <Tooltip.Arrow className="fill-black/80 backdrop-blur" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default AirvanaTooltip;
