import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


export function getDirtyValues<T extends Record<string, any>>(dirtyFields: any, values: T): Partial<T> {
  return Object.keys(dirtyFields).reduce((acc, key) => {
    if (dirtyFields[key] === true) {
      acc[key as keyof T] = values[key as keyof T];
    } else if (typeof dirtyFields[key] === "object" && dirtyFields[key] !== null) {
      acc[key as keyof T] = getDirtyValues(dirtyFields[key], values[key as keyof T]) as any; // Use 'as any' to bypass type error
    }
    return acc;
  }, {} as Partial<T>);
}