import { AxiosError, AxiosResponse } from "axios/index";
import { useMutation, UseMutationResult, useQuery } from "@tanstack/react-query";
import { sendContact, leadWorkflow } from "src/api/admin/leadtool";

type IUseLeadToolGHLSendContactRequest = () => UseMutationResult<
  AxiosResponse<AdminAPI.LeadTool.GHL.SendContact.Response>,
  AxiosError<AdminAPI.LeadTool.GHL.SendContact.ErrorResponse>,
  AdminAPI.LeadTool.GHL.SendContact.Request
>;

export const useLeadToolGHLSendContactRequest: IUseLeadToolGHLSendContactRequest = () =>
  useMutation({
    mutationFn: async (data) => await sendContact(data),
  });

type IUseLeadWorkflowRequest = () => UseMutationResult<
  AxiosResponse<AdminAPI.LeadTool.LeadWorkflow.Response>,
  AxiosError<AdminAPI.LeadTool.LeadWorkflow.ErrorResponse>,
  AdminAPI.LeadTool.LeadWorkflow.Request
>;

export const useLeadToolLeadWorkflowRequest: IUseLeadWorkflowRequest = () =>
  useMutation({
    mutationFn: async (data) => await leadWorkflow(data),
  });
