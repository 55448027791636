/* eslint-disable max-len */
import React from "react";

interface IconLogoProps {
  size?: number;
  appearance?: "default" | "white" | "primary";
}

const IconLogo: React.FC<IconLogoProps> = ({ appearance = "default", size = 35 }) => {
  const color = React.useMemo(() => {
    if (appearance === "white") {
      return "#fff";
    }
    if (appearance === "primary") {
      return "#FF5E04";
    }
    return "#252323";
  }, [appearance]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="37" viewBox="0 0 43 37" fill="none">
      <path
        d="M20.5268 8.6717C20.2734 8.99545 19.7896 9.98982 19.4671 10.8686C19.1215 11.7704 18.7529 12.5336 18.6377 12.6261C18.5225 12.6954 18.4304 12.9267 18.4304 13.1579C18.4304 13.3892 18.177 14.0829 17.8544 14.7073C17.5319 15.3317 17.2785 15.9098 17.2785 16.0023C17.2785 16.0948 17.0251 16.7192 16.7256 17.3898C16.4261 18.0604 16.0114 19.1242 15.781 19.7254C15.5506 20.3267 15.2742 20.8817 15.182 20.9511C15.0668 21.0204 14.9747 21.2286 14.9747 21.3904C14.9747 21.5754 14.7213 22.2692 14.4448 22.9398C14.1453 23.6104 13.7537 24.5354 13.5924 24.9748C13.4081 25.4142 13.1316 25.9923 12.9704 26.2467C12.8091 26.5011 12.6709 27.0792 12.6709 27.5648C12.6709 28.6517 13.5233 29.3454 14.3757 28.9523C15.0208 28.6517 16.0805 26.5936 17.3937 23.0092C18.5686 19.8179 21.4023 13.0654 21.6096 12.9961C21.7248 12.9729 21.8861 13.1579 21.9552 13.4586C22.1165 14.1292 23.1762 16.8117 23.6139 17.6211C23.7982 17.9448 23.9595 18.3611 23.9595 18.5461C23.9595 18.7311 24.1668 19.2398 24.3972 19.7254C24.6276 20.1879 24.904 20.8586 24.9732 21.1823C25.0653 21.5061 25.3418 22.2692 25.5952 22.8704C26.102 24.0036 26.4476 24.7898 26.724 25.5529C27.9681 28.7673 28.4749 29.3454 29.6268 28.9061C30.6866 28.4898 30.5253 26.7786 29.2121 24.2811C28.9818 23.8417 28.7975 23.3098 28.7975 23.1248C28.7975 22.9398 28.5901 22.4542 28.3367 22.0842C28.0833 21.6911 27.8759 21.2748 27.8759 21.1129C27.8759 20.9511 27.6225 20.2804 27.323 19.6098C27.0235 18.9392 26.4015 17.4361 25.9408 16.3029C25.457 15.1467 24.9501 13.9673 24.7889 13.6436C24.6046 13.3198 24.3511 12.6492 24.1899 12.1404C24.0286 11.6317 23.7521 11.0073 23.6139 10.7529C23.4527 10.4986 23.1992 9.89732 23.038 9.4117C22.6003 8.0242 21.3562 7.6542 20.5268 8.6717Z"
        fill={color}
      />
    </svg>
  );
};

export default IconLogo;
