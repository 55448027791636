"use client";

import React, { useMemo, useState } from "react";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { Plus, Minus, ListCollapse } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "src/components/ui/dialog";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "src/components/ui/accordion";
import { MultiSelect, SelectOption } from "src/components/ui/multiSelect";
import { useGetMonitorsCategoriesRequest } from "src/hooks/api/local-pulse";
import { UrlInputForm } from "./url-input-form";
import { MonitorConfig } from "../local-monitor/CustomDialogContent";
import DateRestrictForm from "./date-restrict-form";
import ExistingMonitorForm from "./existing-monitor-form";

interface QueryInputFormProps {
  queries: MonitorConfig[];
  setQueries: (queries: MonitorConfig[]) => void;
  onQueriesChange: (queries: AgentAPI.LocalPulse.MonitorConfig[]) => void;
  error: {
    id: number;
    query: boolean;
    urls: boolean;
  };
  setError: (error: { id: number; query: boolean; urls: boolean }) => void;
}

export function QueryInputForm({ queries, setQueries, onQueriesChange, error, setError }: QueryInputFormProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { data: categoriesData, isLoading: isCategoriesLoading } = useGetMonitorsCategoriesRequest();
  const [loadDateRestrict, setLoadDateRestrict] = React.useState(true);

  const categories = useMemo(() => {
    if (!categoriesData || !categoriesData.data) return [];
    return categoriesData.data.map((category: { id: number; name: string }) => ({
      value: category.id,
      label: category.name,
    }));
  }, [categoriesData]);

  const defaultQuery: MonitorConfig = {
    id: Date.now(),
    name: "New Monitor",
    query: "",
    date_restrict: "y1",
    exclude_terms: "",
    exact_terms: "",
    duplicate_filter: true,
    hq: "",
    num_of_results: 10,
    showDetails: false,
    urls: [],
    categories: [],
    method: "standard",
    area_ids: [],
  };

  const addQuery = () => {
    const newQueries = [...queries, { ...defaultQuery, name: "New Monitor" }];
    setQueries(newQueries);
    onQueriesChange(newQueries);
  };

  const removeQuery = (index: number) => {
    const newQueries = queries.filter((_, i) => i !== index);
    setQueries(newQueries);
    onQueriesChange(newQueries);
  };

  const updateQuery = (index: number, value: Partial<MonitorConfig>) => {
    const newQueries = queries.map((query, i) => (i === index ? { ...query, ...value } : query));
    setError({ ...error, query: false });
    setQueries(newQueries);
    onQueriesChange(newQueries);
  };

  const showDetails = (index: number) => {
    const newQueries = queries.map((query, i) => (i === index ? { ...query, showDetails: !query.showDetails } : query));
    setQueries(newQueries);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center flex-wrap gap-2">
        <Label htmlFor="queries" className="text-base font-bold">
          Monitors
        </Label>
        <div className="flex gap-1 w-full h-10">
          <ExistingMonitorForm
            name="Existing Monitor"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onSelectMonitors={(monitors) => {
              setQueries(monitors);
              onQueriesChange(monitors);
            }}
            currentMonitors={queries}
          />
          <Button type="button" variant="outline" onClick={addQuery} size="sm" className="text-xs px-2 w-full !h-full">
            <Plus className="h-3 w-3 mr-2" />
            New Monitor
          </Button>
        </div>
      </div>
      <Accordion type="multiple">
        <div className="max-h-[300px] overflow-y-auto space-y-2 customScrollbar2">
          {queries.map((query, index) => (
            <AccordionItem
              key={query.id}
              className="space-y-2 border border-border rounded-md p-3 py-1 bg-background shadow-sm"
              value={query.id.toString()}
            >
              <AccordionTrigger className="py-2 text-base tracking-normal">{query.name}</AccordionTrigger>
              <AccordionContent>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    <Label htmlFor="queries" className="text-xs tracking-normal">
                      Search Query
                    </Label>
                    <div className="flex items-center space-x-2">
                      <Input
                        className={`text-xs ${error.query && error.id === query.id && "!border-red-500"}`}
                        value={query.query}
                        onChange={(e) => updateQuery(index, { query: e.target.value })}
                        placeholder="Enter query"
                      />
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        onClick={() => showDetails(index)}
                        aria-label="show query details"
                        className={`${query.showDetails ? "bg-primary text-white" : ""}`}
                      >
                        <ListCollapse className="h-4 w-4" />
                      </Button>
                      <Button type="button" variant="outline" size="icon" onClick={() => removeQuery(index)} aria-label="Remove query">
                        <Minus className="h-4 w-4" />
                      </Button>
                    </div>
                    {error.query && error.id === query.id && <p className="text-xs text-destructive">Please enter a query</p>}
                  </div>
                  {query.showDetails && (
                    <div className="pl-4 py-2 border-l-2 border-secondary">
                      <div className="grid grid-cols-2 gap-2">
                        <div className="space-y-1">
                          <Label htmlFor={`num-of-results-${index}`} className="text-xs">
                            Number of Results
                          </Label>
                          <Input
                            id={`num-of-results-${index}`}
                            className="text-xs"
                            value={query.num_of_results || ""}
                            type="number"
                            onChange={(e) => updateQuery(index, { num_of_results: parseInt(e.target.value, 10) })}
                            placeholder="Number of Results"
                          />
                        </div>
                        <div className="space-y-1">
                          <Label htmlFor={`exact-terms-${index}`} className="text-xs">
                            Exact Terms
                          </Label>
                          <Input
                            id={`exact-terms-${index}`}
                            className="text-xs"
                            value={query.exact_terms || ""}
                            onChange={(e) => updateQuery(index, { exact_terms: e.target.value })}
                            placeholder="Exact terms"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <div className="space-y-1">
                          <Label htmlFor={`exclude-terms-${index}`} className="text-xs">
                            Exclude Terms
                          </Label>
                          <Input
                            className="text-xs"
                            id={`exclude-terms-${index}`}
                            value={query.exclude_terms || ""}
                            onChange={(e) => updateQuery(index, { exclude_terms: e.target.value })}
                            placeholder="Terms to exclude"
                          />
                        </div>
                        <div className="space-y-1">
                          <Label htmlFor={`hq-${index}`} className="text-xs">
                            High Quality Terms
                          </Label>
                          <Input
                            className="text-xs"
                            id={`hq-${index}`}
                            value={query.hq || ""}
                            onChange={(e) => updateQuery(index, { hq: e.target.value })}
                            placeholder="High Quality Terms"
                          />
                        </div>
                      </div>
                      <div className="space-y-1">
                        <DateRestrictForm
                          initialDateRestrict={query.date_restrict}
                          onDateRestrictChange={(dateRestrict) => {
                            updateQuery(index, { date_restrict: dateRestrict });
                          }}
                          load={loadDateRestrict}
                          setLoad={setLoadDateRestrict}
                        />
                      </div>
                      <div className="space-y-1">
                        <Label htmlFor={`categories-${index}`} className="text-xs">
                          Categories
                        </Label>
                        <MultiSelect
                          multiple
                          options={categories}
                          value={categories ? categories.filter((cat) => query.categories?.includes(cat.value)) : []}
                          onChange={(values: SelectOption[]) => {
                            updateQuery(index, {
                              categories: values.map((v) => Number(v.value)),
                              categories_name: values.map((v) => v.label),
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex flex-col gap-1">
                    <UrlInputForm
                      urls={query.urls || []}
                      onUrlsChange={(urls) => {
                        updateQuery(index, { urls });
                        setError({ ...error, urls: false });
                      }}
                    />
                    {error.urls && error.id === query.id && <p className="text-xs text-destructive">Please enter at least one URL</p>}
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </div>
      </Accordion>
    </div>
  );
}
