import React, { useCallback, useMemo, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { RefreshCcw, Plus } from "lucide-react";
import { Button } from "src/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "src/components/ui/dialog";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "src/components/ui/select";
import { useStateListRequest } from "src/hooks/api/area";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getAllAreaList } from "src/api/area";
import { Checkbox } from "src/components/ui/checkbox";

export default function AreaInputForm({
  initialSelectedAreas,
  initialSelectedAreaNames,
  handleAreaChange,
}: {
  initialSelectedAreas: number[];
  initialSelectedAreaNames: string[];
  handleAreaChange: (areas: { area_ids: number[]; area_names: string[] }) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = React.useState<AgentAPI.LocalPulse.List.Filter>({});
  const [tempSearch, setTempSearch] = useState(search);
  const { data: stateData } = useStateListRequest();
  const [selectedAreas, setSelectedAreas] = useState<number[]>(initialSelectedAreas);
  const [selectedAreaNames, setSelectedAreaNames] = useState<string[]>(initialSelectedAreaNames);
  const parentRef = useRef<HTMLDivElement>(null);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useInfiniteQuery({
    queryKey: ["area", search, filter],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getAllAreaList({
        page: pageParam,
        search,
        ...filter,
      });
      return response;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.next) {
        return pages.length + 1;
      }
      return undefined;
    },
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchInterval: 1000 * 60 * 5,
  });

  const allCities: API.Area.AreaData[] = useMemo(() => data?.pages.flatMap((page) => page.data.results) ?? [], [data]);

  const debouncedSetSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearch(value);
      }, 500),
    [], // Empty dependency array since setSearch is stable
  );

  const handleFilterChange = (name: string, value: string) => {
    if (value === "") {
      // Create a new object without the specified property
      const { [name as keyof AgentAPI.LocalPulse.List.Filter]: removed, ...rest } = filter;
      setFilter(rest);
    } else {
      setFilter({
        ...filter,
        [name]: value,
      });
    }
  };

  // Load more when scrolling near bottom
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight * 1.5 && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  const renderCityList = useMemo(() => {
    if (isLoading) {
      return <div className="flex items-center justify-center h-full">Loading areas...</div>;
    }

    return (
      <>
        {/* Selected Areas Section */}
        {selectedAreas.length > 0 && (
          <>
            <div className="p-2 bg-slate-100 font-medium">Selected Areas</div>
            {selectedAreas.map((areaId, index) => {
              const city = allCities.find((c) => c.id === areaId);
              if (city) return null;

              return (
                <div
                  key={areaId}
                  className="flex items-center space-x-2 p-2 hover:bg-slate-200 rounded cursor-pointer bg-slate-50"
                  onClick={() => {
                    setSelectedAreas(selectedAreas.filter((m) => m !== areaId));
                    setSelectedAreaNames(selectedAreaNames.filter((_, i) => i !== index));
                  }}
                >
                  <Checkbox checked={selectedAreas.some((m) => m === areaId)} />
                  <span className="text-sm">{selectedAreaNames[index]}</span>
                </div>
              );
            })}
            <div className="border-b my-2" />
          </>
        )}

        {/* Available Areas Section */}
        {allCities.length === 0 ? (
          <div className="flex items-center justify-center h-full">No areas found</div>
        ) : (
          allCities.map((city) => (
            <div
              key={city.id}
              className="flex items-center space-x-2 p-2 hover:bg-slate-200 rounded cursor-pointer"
              onClick={() => {
                const stateCode = stateData?.data?.find((state) => state.id === city.state)?.code || "";
                const areaName = `${city.name}, ${stateCode}`;

                if (selectedAreas.some((m) => m === city.id)) {
                  setSelectedAreas(selectedAreas.filter((m) => m !== city.id));
                  setSelectedAreaNames(selectedAreaNames.filter((m) => m !== areaName));
                } else {
                  setSelectedAreas([...selectedAreas, Number(city.id)]);
                  setSelectedAreaNames([...selectedAreaNames, areaName]);
                }
              }}
            >
              <Checkbox checked={selectedAreas.some((m) => m === city.id)} />
              <span className="text-sm">
                {city.name}, {stateData?.data?.find((state) => state.id === city.state)?.code || ""}
              </span>
            </div>
          ))
        )}
      </>
    );
  }, [allCities, isLoading, selectedAreas, selectedAreaNames, stateData]);

  const handleDialogOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      setSelectedAreas(initialSelectedAreas);
      setSelectedAreaNames(initialSelectedAreaNames);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleDialogOpenChange}>
      <DialogTrigger asChild>
        <Button type="button" variant="outline" size="sm" className="text-xs px-2 w-fit">
          <Plus className="h-3 w-3 mr-2" />
          Select Area
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Select Areas to link into this monitor</DialogTitle>
        </DialogHeader>
        <DialogDescription />
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Search areas..."
            className="w-full h-9 rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
            onChange={(e) => {
              setTempSearch(e.target.value);
              debouncedSetSearch(e.target.value);
            }}
            value={tempSearch}
          />
        </div>
        <div className="flex flex-row gap-2">
          <Select
            value={filter.level?.toString() || ""}
            onValueChange={(value) => {
              // If user selects "none" or the same value, clear the selection
              if (value === "none" || value === filter.level?.toString()) {
                handleFilterChange("level", "");
              } else {
                handleFilterChange("level", value);
              }
            }}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select Level" />
            </SelectTrigger>
            <SelectContent className="z-[1000]">
              <SelectGroup>
                <SelectLabel>Level</SelectLabel>
                <SelectItem value="none">Select Level</SelectItem>
                <SelectItem value="3">City</SelectItem>
                <SelectItem value="2">County</SelectItem>
                <SelectItem value="1">State</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select
            value={filter.state?.toString() || ""}
            onValueChange={(value) => {
              // If user selects "none" or the same value, clear the selection
              if (value === "none" || value === filter.state?.toString()) {
                handleFilterChange("state", "");
              } else {
                handleFilterChange("state", value);
              }
            }}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select state" />
            </SelectTrigger>
            <SelectContent className="z-[1000]">
              <SelectGroup>
                <SelectLabel>States</SelectLabel>
                <SelectItem value="none">Select a state</SelectItem>
                {stateData?.data?.map((state) => (
                  <SelectItem key={state.id} value={state.id.toString()}>
                    {state.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {/* Virtualized List */}
        <div ref={parentRef} onScroll={handleScroll} className="flex-1 overflow-auto min-h-[400px] max-h-[50vh]">
          {renderCityList}
        </div>
        <DialogFooter>
          <Button
            type="button"
            variant="outline"
            size="sm"
            className="text-xs px-2 w-fit"
            onClick={() => {
              handleAreaChange({
                area_ids: selectedAreas,
                area_names: selectedAreaNames,
              });
              setIsOpen(false);
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
