/** @jsxImportSource @emotion/react */
import React from "react";
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
    "container" |
    "button" |
    "submitButton" |
    "heading" |
    "dropdown" |
    "footer" |
    "scrollable" |
    "checkboxButtonGroup" |
    "checkboxButton" |
    "checkboxButtonInput" |
    "checkboxButtonLabel" |
    "clearButton"
]>;

const styles: IStyles = {
  container: () => css({
    position: "relative",
    display: "inline-block",
  }),
  button: (theme) => css({
    width: "100%",
    background: theme.colorBackground,
  }),
  submitButton: (theme) => css({
    width: "100%",
    [theme.mq.md]: {
      width: "auto",
    },
  }),
  dropdown: (theme) => css({
    zIndex: 50,
    position: "absolute",
    width: "333px",
    top: "100%",
    marginTop: theme.spaces.md,
    left: "50%",
    transform: "translateX(-50%)",
    background: theme.colorWhite,
    borderRadius: theme.borderRadius,
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
    paddingTop: theme.spaces.lg,
    paddingBottom: theme.spaces.lg,
    paddingLeft: theme.spaces.lg,
    paddingRight: theme.spaces.xxs,
    height: "auto",
    maxHeight: "80vh",
    display: "flex",
    flexDirection: "column",
    [theme.mq.md]: {
      position: "static",
      transform: "none",
      boxShadow: "none",
      width: "100%",
      padding: 0,
      borderRadius: 0,
      maxHeight: "none",
    },
  }),
  heading: (theme) => css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spaces.md,
    paddingRight: theme.spaces.lg - theme.spaces.xxs,
    flex: "0 1 auto",
    minWidth: 0,
  }),
  scrollable: (theme) => css({
    maxHeight: `calc(80vh - ${theme.spaces.lg * 3 + theme.spaces.md}px)`,
    height: "auto",
    overflowY: "auto",
    paddingRight: theme.spaces.lg - theme.spaces.xxs,
    scrollbarWidth: "thin",
    flex: "1 1 auto",
    minHeight: 0,
  }),
  footer: (theme) => css({
    flex: "0 1 auto",
    minWidth: 0,
    paddingRight: theme.spaces.lg - theme.spaces.xxs,
    paddingTop: theme.spaces.lg,
  }),
  clearButton: (theme) => css({
    display: "inline-flex",
  }),
  checkboxButtonGroup: (theme) => css({
    display: "grid",
    grid: "auto-flow/repeat(2, 1fr)",
    columnGap: theme.spaces.xs,
    rowGap: theme.spaces.xs,
  }),
  checkboxButton: (theme) => css({
    position: "relative",
  }),
  checkboxButtonInput: (theme) => css({
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    "&:checked + label": {
      background: theme.colorPrimary,
      color: theme.colorWhite,
      borderColor: theme.colorPrimary,
    },
  }),
  checkboxButtonLabel: (theme) => css({
    cursor: "pointer",
    border: `1px solid ${theme.colorOutlineLight}`,
    borderRadius: theme.borderRadius,
    outline: "none",
    display: "block",
    textAlign: "center",
    padding: `${theme.spaces.xs}px ${theme.spaces.sm}px`,
    color: theme.colorTextSecondary,
    fontWeight: theme.fontWeight.semiBold,
    background: theme.colorSecondary,
    transition: "all 0.2s ease",
  }),
};

export default styles;
