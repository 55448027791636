// eslint-disable-next-line no-shadow
export enum STRRegulations {
  POSITIVE = "positive",
  NEUTRAL = "neutral",
  NEGATIVE = "negative",
  UNKNOWN = "unknown",
}

// eslint-disable-next-line no-shadow
export enum STRGrade {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  F = "F",
  NOT_SET = "not_set",
}
